/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-18 14:19:40
 * @LastEditors: changfeng
 * @LastEditTime: 2024-02-18 11:51:20
 */
const TOKEN_KEY = 'Authorization'

  
export const SaveTokenToLocalStorage = (token: string) => {
    localStorage.setItem('Authorization', token);
  };
  
  export const MoveTokenFromLocalStorage = async (): Promise<void> => {
    await localStorage.removeItem('Authorization');
  };
  
export const getAccessToken = (): string | null => {
     return localStorage.getItem('Authorization');
  };

export function saveAs(blob, filename = 'export', outputSuffix = 'xlsx') {
  if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
  } else {
      let link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      link.href= window.URL.createObjectURL(new Blob([blob]))
      link.download = `${filename}${outputSuffix}`;
      link.click();
      window.URL.revokeObjectURL(link.href);
  }
}


  export const TimezoneList = [
    {
      value: 'Pacific/Auckland',
      label: '(UTC+12:00)太平洋/奥克兰',
    },
    {
      value: 'Pacific/Majuro',
      label: '(UTC+12:00)太平洋/马朱罗',
    },
    {
      value: 'Asia/Kamchatka',
      label: '(UTC+11:00)亚洲/堪察加半岛',
    },
    {
      value: 'Asia/Magadan',
      label: '(UTC+11:00)亚洲/马加丹',
    },
    {
      value: 'Asia/Vladivostok',
      label: '(UTC+10:00)亚洲/海参崴',
    },
    {
      value: 'Australia/Sydney',
      label: '(UTC+10:00)澳洲/悉尼',
    },
    {
      value: 'Asia/Jayapura',
      label: '(UTC+09:00)亚洲/贾亚普拉',
    },
    {
      value: 'Asia/Seoul',
      label: '(UTC+09:00)亚洲/首尔',
    },
    {
      value: 'Asia/Tokyo',
      label: '(UTC+09:00)亚洲/东京',
    },
    {
      value: 'Asia/Yakutsk',
      label: '(UTC+09:00)亚洲/雅库茨克',
    },
    {
      value: 'Australia/Broken_Hill',
      label: '(UTC+09:30)澳洲/布罗肯希尔',
    },
    {
      value: 'Asia/Hong_Kong',
      label: '(UTC+08:00)亚洲/香港',
    },
    {
      value: 'Asia/Irkutsk',
      label: '(UTC+08:00)亚洲/伊尔库茨克',
    },
    {
      value: 'Asia/Kuala_Lumpur',
      label: '(UTC+08:00)亚洲/吉隆坡',
    },
    {
      value: 'Asia/Makassar',
      label: '(UTC+08:00)亚洲/马卡萨尔',
    },
    {
      value: 'Asia/Manila',
      label: '(UTC+08:00)亚洲/马尼拉',
    },
    {
      value: 'Asia/Shanghai',
      label: '(UTC+08:00)亚洲/北京',
    },
    {
      value: 'Asia/Singapore',
      label: '(UTC+08:00)亚洲/新加坡',
    },
    {
      value: 'Asia/Taipei',
      label: '(UTC+08:00)亚洲/台北',
    },
    {
      value: 'Australia/Perth',
      label: '(UTC+08:00)澳洲/珀斯',
    },
    {
      value: 'Asia/Bangkok',
      label: '(UTC+07:00)亚洲/曼谷',
    },
    {
      value: 'Asia/Ho_Chi_Minh',
      label: '(UTC+07:00)亚洲/胡志明市',
    },
    {
      value: 'Asia/Jakarta',
      label: '(UTC+07:00)亚洲/雅加达',
    },
    {
      value: 'Asia/Krasnoyarsk',
      label: '(UTC+07:00)亚洲/克拉斯诺亚尔斯克',
    },
    {
      value: 'Asia/Dhaka',
      label: '(UTC+06:00)亚洲/达卡',
    },
    {
      value: 'Asia/Omsk',
      label: '(UTC+06:00)亚洲/鄂木斯克',
    },
    {
      value: 'Asia/Colombo',
      label: '(UTC+05:30)亚洲/科伦坡',
    },
    {
      value: 'Asia/Karachi',
      label: '(UTC+05:00)亚洲/卡拉奇',
    },
    {
      value: 'Asia/Kolkata',
      label: '(UTC+05:30)亚洲/加尔各答',
    },
    {
      value: 'Asia/Yekaterinburg',
      label: '(UTC+05:00)亚洲/叶卡捷琳堡',
    },
    {
      value: 'Indian/Maldives',
      label: '(UTC+05:00)印度洋/马尔代夫',
    },
    {
      value: 'Asia/Dubai',
      label: '(UTC+04:00)亚洲/迪拜',
    },
    {
      value: 'Asia/Muscat',
      label: '(UTC+04:00)亚洲/麝香葡萄酒',
    },
    {
      value: 'Indian/Mauritius',
      label: '(UTC+04:00)印度洋/毛里求斯',
    },
    {
      value: 'Africa/Nairobi',
      label: '(UTC+03:00)非洲/内罗毕',
    },
    {
      value: 'Asia/Baghdad',
      label: '(UTC+03:00)亚洲/巴格达',
    },
    {
      value: 'Asia/Bahrain',
      label: '(UTC+03:00)亚洲/巴林',
    },
    {
      value: 'Asia/Kuwait',
      label: '(UTC+03:00)亚洲/科威特',
    },
    {
      value: 'Asia/Qatar',
      label: '(UTC+03:00)亚洲/卡塔尔',
    },
    {
      value: 'Asia/Riyadh',
      label: '(UTC+03:00)亚洲/利雅得',
    },
    {
      value: 'Europe/Moscow',
      label: '(UTC+03:00)欧洲/莫斯科',
    },
    {
      value: 'Europe/Samara',
      label: '(UTC+03:00)欧洲/萨马拉',
    },
    {
      value: 'Africa/Cairo',
      label: '(UTC+02:00)非洲/开罗',
    },
    {
      value: 'Africa/Johannesburg',
      label: '(UTC+02:00)非洲/约翰内斯堡',
    },
    {
      value: 'Asia/Amman',
      label: '(UTC+02:00)亚洲/安曼',
    },
    {
      value: 'Asia/Beirut',
      label: '(UTC+02:00)亚洲/贝鲁特',
    },
    {
      value: 'Asia/Gaza',
      label: '(UTC+02:00)亚洲/加沙',
    },
    {
      value: 'Asia/Jerusalem',
      label: '(UTC+02:00)亚洲/耶路撒冷',
    },
    {
      value: 'Asia/Nicosia',
      label: '(UTC+02:00)亚洲/尼科西亚',
    },
    {
      value: 'Europe/Athens',
      label: '(UTC+02:00)欧洲/雅典',
    },
    {
      value: 'Europe/Bucharest',
      label: '(UTC+02:00)欧洲/布加勒斯特',
    },
    {
      value: 'Europe/Helsinki',
      label: '(UTC+02:00)欧洲/赫尔辛基',
    },
    {
      value: 'Europe/Istanbul',
      label: '(UTC+02:00)欧洲/伊斯坦布尔',
    },
    {
      value: 'Europe/Kaliningrad',
      label: '(UTC+02:00)欧洲/加里宁格勒',
    },
    {
      value: 'Europe/Kiev',
      label: '(UTC+02:00)欧洲/基辅',
    },
    {
      value: 'Europe/Riga',
      label: '(UTC+02:00)欧洲/里加',
    },
    {
      value: 'Europe/Sofia',
      label: '(UTC+02:00)欧洲/索非亚',
    },
    {
      value: 'Europe/Tallinn',
      label: '(UTC+02:00)欧洲/塔林',
    },
    {
      value: 'Europe/Vilnius',
      label: '(UTC+02:00)欧洲/维尔纽斯',
    },
    {
      value: 'Africa/Lagos',
      label: '(UTC+01:00)非洲/拉各斯',
    },
    {
      value: 'Africa/Tunis',
      label: '(UTC+01:00)非洲/突尼斯',
    },
    {
      value: 'Europe/Amsterdam',
      label: '(UTC+01:00)欧洲/阿姆斯特丹',
    },
    {
      value: 'Europe/Belgrade',
      label: '(UTC+01:00)欧洲/贝尔格莱德',
    },
    {
      value: 'Europe/Berlin',
      label: '(UTC+01:00)欧洲/柏林',
    },
    {
      value: 'Europe/Bratislava',
      label: '(UTC+01:00)欧洲/布拉迪斯拉发',
    },
    {
      value: 'Europe/Brussels',
      label: '(UTC+01:00)欧洲/布鲁塞尔',
    },
    {
      value: 'Europe/Budapest',
      label: '(UTC+01:00)欧洲/布达佩斯',
    },
    {
      value: 'Europe/Copenhagen',
      label: '(UTC+01:00)欧洲/哥本哈根',
    },
    {
      value: 'Europe/Ljubljana',
      label: '(UTC+01:00)欧洲/卢布尔雅那',
    },
    {
      value: 'Europe/Luxembourg',
      label: '(UTC+01:00)欧洲/卢森堡',
    },
    {
      value: 'Europe/Madrid',
      label: '(UTC+01:00)欧洲/马德里',
    },
    {
      value: 'Europe/Malta',
      label: '(UTC+01:00)欧洲/马耳他',
    },
    {
      value: 'Europe/Oslo',
      label: '(UTC+01:00)欧洲/奥斯陆',
    },
    {
      value: 'Europe/Paris',
      label: '(UTC+01:00)欧洲/巴黎',
    },
    {
      value: 'Europe/Prague',
      label: '(UTC+01:00)欧洲/布拉格',
    },
    {
      value: 'Europe/Rome',
      label: '(UTC+01:00)欧洲/罗马',
    },
    {
      value: 'Europe/Sarajevo',
      label: '(UTC+01:00)欧洲/萨拉热窝',
    },
    {
      value: 'Europe/Skopje',
      label: '(UTC+01:00)欧洲/斯科普里',
    },
    {
      value: 'Europe/Stockholm',
      label: '(UTC+01:00)欧洲/斯德哥尔摩',
    },
    {
      value: 'Europe/Vienna',
      label: '(UTC+01:00)欧洲/维也纳',
    },
    {
      value: 'Europe/Warsaw',
      label: '(UTC+01:00)欧洲/华沙',
    },
    {
      value: 'Europe/Zagreb',
      label: '(UTC+01:00)欧洲/萨格勒布',
    },
    {
      value: 'Europe/Zurich',
      label: '(UTC+01:00)欧洲/苏黎世',
    },
    {
      value: 'Africa/Accra',
      label: '(UTC+00:00)非洲/阿克拉',
    },
    {
      value: 'Africa/Casablanca',
      label: '(UTC+00:00)非洲/卡萨布兰卡',
    },
    {
      value: 'Atlantic/Canary',
      label: '(UTC+00:00)大西洋/加那利群岛',
    },
    {
      value: 'Atlantic/Reykjavik',
      label: '(UTC+00:00)大西洋/雷克雅未克',
    },
    {
      value: 'Europe/Dublin',
      label: '(UTC+00:00)欧洲/都柏林',
    },
    {
      value: 'Europe/Lisbon',
      label: '(UTC+00:00)欧洲/里斯本',
    },
    {
      value: 'Europe/London',
      label: '(UTC+00:00)欧洲/伦敦',
    },
    {
      value: 'Atlantic/Azores',
      label: '(UTC-01:00)大西洋/亚速尔群岛',
    },
    {
      value: 'America/Noronha',
      label: '(UTC-02:00)美洲/诺隆尼亚',
    },
    {
      value: 'America/Argentina/Buenos_Aires',
      label: '(UTC-03:00)美洲/阿根廷/布宜诺斯艾利斯',
    },
    {
      value: 'America/Argentina/Salta',
      label: '(UTC-03:00)美洲/阿根廷/萨尔塔',
    },
    {
      value: 'America/Argentina/San_Luis',
      label: '(UTC-03:00)美洲/阿根廷/圣路易斯',
    },
    {
      value: 'America/Belem',
      label: '(UTC-03:00)美洲/贝伦',
    },
    {
      value: 'America/Montevideo',
      label: '(UTC-03:00)美洲/蒙得维的亚',
    },
    {
      value: 'America/Sao_Paulo',
      label: '(UTC-03:00)美洲/圣保罗',
    },
    {
      value: 'America/St_Johns',
      label: '(UTC-03:30)美洲/圣约翰',
    },
    {
      value: 'America/Asuncion',
      label: '(UTC-04:00)美洲/亚松森',
    },
    {
      value: 'America/Blanc-Sablon',
      label: '(UTC-04:00)美洲/白朗萨布隆',
    },
    {
      value: 'America/Campo_Grande',
      label: '(UTC-04:00)美洲/大坎普',
    },
    {
      value: 'America/Caracas',
      label: '(UTC-04:30)美洲/加拉加斯',
    },
    {
      value: 'America/Halifax',
      label: '(UTC-04:00)美洲/哈利法克斯',
    },
    {
      value: 'America/La_Paz',
      label: '(UTC-04:00)美洲/拉巴斯',
    },
    {
      value: 'America/Port_of_Spain',
      label: '(UTC-04:00)美洲/西班牙港口',
    },
    {
      value: 'America/Puerto_Rico',
      label: '(UTC-04:00)美洲/波多黎各',
    },
    {
      value: 'America/Santiago',
      label: '(UTC-04:00)美洲/圣地亚哥',
    },
    {
      value: 'America/Santo_Domingo',
      label: '(UTC-04:00)美洲/桑托多明哥',
    },
    {
      value: 'America/Atikokan',
      label: '(UTC-05:00)美国/阿提科坎',
    },
    {
      value: 'America/Bogota',
      label: '(UTC-05:00)美洲/波哥大',
    },
    {
      value: 'America/Guayaquil',
      label: '(UTC-05:00)美洲/瓜亚基尔',
    },
    {
      value: 'America/Iqaluit',
      label: '(UTC-05:00)美洲/伊卡鲁伊特',
    },
    {
      value: 'America/Jamaica',
      label: '(UTC-05:00)美洲/牙买加',
    },
    {
      value: 'America/Lima',
      label: '(UTC-05:00)美洲/利马',
    },
    {
      value: 'America/Nassau',
      label: '(UTC-05:00)美洲/拿骚港',
    },
    {
      value: 'America/New_York',
      label: '(UTC-05:00)美洲/纽约',
    },
    {
      value: 'America/Panama',
      label: '(UTC-05:00)美洲/巴拿马',
    },
    {
      value: 'America/Toronto',
      label: '(UTC-05:00)美国/多伦多',
    },
    {
      value: 'America/Chicago',
      label: '(UTC-06:00)美洲/芝加哥',
    },
    {
      value: 'America/Costa_Rica',
      label: '(UTC-06:00)美洲/哥斯达黎加',
    },
    {
      value: 'America/El_Salvador',
      label: '(UTC-06:00)美洲/萨尔瓦多',
    },
    {
      value: 'America/Guatemala',
      label: '(UTC-06:00)美洲/危地马拉',
    },
    {
      value: 'America/Managua',
      label: '(UTC-06:00)美洲/马那瓜',
    },
    {
      value: 'America/Mexico_City',
      label: '(UTC-06:00)美洲/墨西哥城',
    },
    {
      value: 'America/Rainy_River',
      label: '(UTC-06:00)美洲/雷尼里弗',
    },
    {
      value: 'America/Regina',
      label: '(UTC-06:00)美洲/里贾纳',
    },
    {
      value: 'America/Tegucigalpa',
      label: '(UTC-06:00)美洲/特古西加尔巴',
    },
    {
      value: 'Pacific/Easter',
      label: '(UTC-06:00)太平洋/复活节岛',
    },
    {
      value: 'Pacific/Galapagos',
      label: '(UTC-06:00)太平洋/加拉帕戈斯群岛',
    },
    {
      value: 'America/Dawson_Creek',
      label: '(UTC-07:00)美洲/道森河',
    },
    {
      value: 'America/Denver',
      label: '(UTC-07:00)美洲/丹佛',
    },
    {
      value: 'America/Edmonton',
      label: '(UTC-07:00)美洲/艾德蒙顿',
    },
    {
      value: 'America/Hermosillo',
      label: '(UTC-07:00)美洲/赫莫西洛',
    },
    {
      value: 'America/Mazatlan',
      label: '(UTC-07:00)美洲/馬薩特蘭',
    },
    {
      value: 'America/Phoenix',
      label: '(UTC-07:00)美洲/凤凰城',
    },
    {
      value: 'America/Dawson',
      label: '(UTC-08:00)美洲/道森',
    },
    {
      value: 'America/Los_Angeles',
      label: '(UTC-08:00)美洲/洛杉矶',
    },
    {
      value: 'America/Tijuana',
      label: '(UTC-08:00)美洲/蒂华纳',
    },
    {
      value: 'America/Vancouver',
      label: '(UTC-08:00)美洲/温哥华',
    },
    {
      value: 'America/Anchorage',
      label: '(UTC-09:00)美洲/安克雷奇',
    },
    {
      value: 'Pacific/Honolulu',
      label: '(UTC-10:00)太平洋/火奴鲁鲁',
    },
    {
      value: 'Pacific/Apia',
      label: '(UTC-11:00)太平洋/阿皮亚',
    },
    {
      value: 'Pacific/Midway',
      label: '(UTC-11:00)太平洋/中途岛',
    },
    {
      value: 'Pacific/Niue',
      label: '(UTC-11:00)太平洋/纽埃',
    },
    {
      value: 'Pacific/Pago_Pago',
      label: '(UTC-11:00)太平洋/帕果帕果',
    },
  ];

  export const IndustryList = [
    {
      value: '2901',
      label: '消费类电子产品及电器',
      children: [
        {
          value: '290101',
          label: '消费类电子产品及周边',
        },
        {
          value: '290102',
          label: '电器',
        },
        {
          value: '290103',
          label: '其他消费类电子产品及电器',
        },
        {
          value: '290104',
          label: '手机',
        },
        {
          value: '290105',
          label: '手机配件',
        },
        {
          value: '290106',
          label: '电脑',
        },
        {
          value: '290107',
          label: '电脑配件',
        },
        {
          value: '290108',
          label: '消费类电子产品及电器电商',
        },
        {
          value: '290109',
          label: '消费类电子产品及电器线下零售',
        },
      ],
    },
    {
      value: '2902',
      label: '快速消费品',
      children: [
        {
          value: '290201',
          label: '日化用品',
        },
        {
          value: '290202',
          label: '一般化妆品',
        },
        {
          value: '290203',
          label: '高档化妆品',
        },
        {
          value: '290204',
          label: '特殊化妆品',
        },
        {
          value: '290205',
          label: '烟',
        },
        {
          value: '290206',
          label: '宠物用品',
        },
        {
          value: '290207',
          label: '母婴用品',
        },
        {
          value: '290208',
          label: '其他快速消费品',
        },
        {
          value: '290209',
          label: '成人用品',
        },
        {
          value: '290210',
          label: '化妆品及日化电商',
        },
        {
          value: '290211',
          label: '化妆品及日化线下零售',
        },
        {
          value: '290212',
          label: '母婴用品电商',
        },
        {
          value: '290213',
          label: '母婴用品线下零售',
        },
        {
          value: '290214',
          label: '烟酒电商',
        },
        {
          value: '290215',
          label: '烟酒线下零售',
        },
        {
          value: '290216',
          label: '宠物用品电商',
        },
        {
          value: '290217',
          label: '宠物用品线下零售',
        },
        {
          value: '290218',
          label: '大麻制品',
        },
      ],
    },
    {
      value: '2903',
      label: '食品饮料',
      children: [
        {
          value: '290301',
          label: '休闲零食',
        },
        {
          value: '290302',
          label: '饮料冲调',
        },
        {
          value: '290303',
          label: '酒类',
        },
        {
          value: '290304',
          label: '乳制品及乳制品饮料',
        },
        {
          value: '290305',
          label: '日常食品',
        },
        {
          value: '290306',
          label: '生鲜',
        },
        {
          value: '290307',
          label: '功能性食品',
        },
        {
          value: '290308',
          label: '其他食用初级农产品',
        },
        {
          value: '290310',
          label: '其他食品饮料',
        },
        {
          value: '290311',
          label: '食品饮料(非生鲜)电商',
        },
        {
          value: '290312',
          label: '食品饮料(非生鲜)线下零售',
        },
        {
          value: '290313',
          label: '生鲜电商',
        },
        {
          value: '290314',
          label: '生鲜线下零售',
        },
      ],
    },
    {
      value: '2904',
      label: '服装配饰',
      children: [
        {
          value: '290401',
          label: '一般服装鞋帽',
        },
        {
          value: '290402',
          label: '高档服装鞋帽',
        },
        {
          value: '290403',
          label: '一般箱包眼镜',
        },
        {
          value: '290404',
          label: '高档箱包眼镜',
        },
        {
          value: '290405',
          label: '一般钟表',
        },
        {
          value: '290406',
          label: '高档钟表',
        },
        {
          value: '290407',
          label: '一般珠宝饰品',
        },
        {
          value: '290408',
          label: '高档珠宝饰品',
        },
        {
          value: '290409',
          label: '其他服装配饰',
        },
        {
          value: '290410',
          label: '服装配饰电商',
        },
        {
          value: '290411',
          label: '服装配饰线下零售',
        },
      ],
    },
    {
      value: '2905',
      label: '医疗&药品',
      children: [
        {
          value: '290501',
          label: '医疗机构',
        },
        {
          value: '290502',
          label: '医疗器械',
        },
        {
          value: '290503',
          label: '医疗周边服务',
        },
        {
          value: '290504',
          label: '其他医疗',
        },
        {
          value: '290505',
          label: '药品',
        },
        {
          value: '290506',
          label: '保健品',
        },
        {
          value: '290507',
          label: '医疗综合服务平台',
        },
        {
          value: '290508',
          label: '兽药',
        },
        {
          value: '290509',
          label: '网上药店/医药电商',
        },
        {
          value: '290510',
          label: '药店/医药线下零售',
        },
        {
          value: '290511',
          label: '处方药',
        },
        {
          value: '290512',
          label: '药妆',
        },
      ],
    },
    {
      value: '2906',
      label: '商务服务',
      children: [
        {
          value: '290601',
          label: '安全安保',
        },
        {
          value: '290602',
          label: '出版传媒',
        },
        {
          value: '290603',
          label: '包装印刷',
        },
        {
          value: '290604',
          label: '中介服务',
        },
        {
          value: '290605',
          label: '咨询服务',
        },
        {
          value: '290606',
          label: '广告公关营销服务',
        },
        {
          value: '290607',
          label: '商演会展',
        },
        {
          value: '290608',
          label: '设计',
        },
        {
          value: '290609',
          label: '会计税务',
        },
        {
          value: '290610',
          label: '法律服务',
        },
        {
          value: '290611',
          label: '人力资源服务',
        },
        {
          value: '290612',
          label: '代工服务',
        },
        {
          value: '290613',
          label: '信息安全',
        },
        {
          value: '290614',
          label: '其他商务服务',
        },
      ],
    },
    {
      value: '2907',
      label: '生活服务',
      children: [
        {
          value: '290701',
          label: '综合服务',
        },
        {
          value: '290702',
          label: '便民服务',
        },
        {
          value: '290703',
          label: '家政服务',
        },
        {
          value: '290704',
          label: '护养服务',
        },
        {
          value: '290705',
          label: '摄影',
        },
        {
          value: '290706',
          label: '美容美发',
        },
        {
          value: '290707',
          label: '回收买卖',
        },
        {
          value: '290708',
          label: '租赁服务',
        },
        {
          value: '290709',
          label: '婚恋服务',
        },
        {
          value: '290710',
          label: '配送服务',
        },
        {
          value: '290711',
          label: '移民服务',
        },
        {
          value: '290712',
          label: '公用服务',
        },
        {
          value: '290713',
          label: '其他生活服务',
        },
        {
          value: '290715',
          label: '结婚服务',
        },
        {
          value: '290716',
          label: '丧葬服务',
        },
      ],
    },
    {
      value: '2908',
      label: '房地产',
      children: [
        {
          value: '290801',
          label: '房地产开发商',
        },
        {
          value: '290802',
          label: '房地产中介',
        },
        {
          value: '290803',
          label: '物业管理公司',
        },
        {
          value: '290804',
          label: '房地产销售代理',
        },
        {
          value: '290805',
          label: '公寓租赁',
        },
        {
          value: '290806',
          label: '其他房地产',
        },
        {
          value: '290807',
          label: '房地产综合服务平台',
        },
      ],
    },
    {
      value: '2909',
      label: '家居建材',
      children: [
        {
          value: '290901',
          label: '家装主材',
        },
        {
          value: '290902',
          label: '五金电工',
        },
        {
          value: '290903',
          label: '装修设计',
        },
        {
          value: '290904',
          label: '家具',
        },
        {
          value: '290905',
          label: '家居饰品',
        },
        {
          value: '290906',
          label: '其他家居建材',
        },
        {
          value: '290907',
          label: '日用百货',
        },
        {
          value: '290908',
          label: '装修环境治理',
        },
        {
          value: '290909',
          label: '家装辅材',
        },
        {
          value: '290910',
          label: '建筑工程',
        },
        {
          value: '290911',
          label: '家居建材综合服务平台',
        },
        {
          value: '290912',
          label: '家居建材电商',
        },
        {
          value: '290913',
          label: '家居建材线下零售',
        },
      ],
    },
    {
      value: '2910',
      label: '教育培训',
      children: [
        {
          value: '291001',
          label: '幼儿教育',
        },
        {
          value: '291002',
          label: '中小学教育',
        },
        {
          value: '291003',
          label: '学历教育',
        },
        {
          value: '291004',
          label: '语言及留学',
        },
        {
          value: '291005',
          label: '兴趣培训',
        },
        {
          value: '291006',
          label: '职业教育',
        },
        {
          value: '291007',
          label: '企业及拓展',
        },
        {
          value: '291008',
          label: '其他教育培训',
        },
        {
          value: '291009',
          label: '特殊人群教育',
        },
      ],
    },
    {
      value: '2911',
      label: '出行旅游',
      children: [
        {
          value: '291101',
          label: '景点',
        },
        {
          value: '291102',
          label: '酒店住宿',
        },
        {
          value: '291103',
          label: 'OTA(Online Travel Agent)',
        },
        {
          value: '291104',
          label: '旅行社',
        },
        {
          value: '291105',
          label: '公共交通',
        },
        {
          value: '291109',
          label: '商旅票务代理',
        },
        {
          value: '291111',
          label: '其他出行旅游',
        },
      ],
    },
    {
      value: '2912',
      label: '社会公共',
      children: [
        {
          value: '291201',
          label: '文化和旅游部',
        },
        {
          value: '291202',
          label: '社会组织',
        },
        {
          value: '291203',
          label: '市政建设',
        },
        {
          value: '291204',
          label: '宗教',
        },
        {
          value: '291205',
          label: '政治',
        },
        {
          value: '291206',
          label: '其他社会公共',
        },
        {
          value: '291207',
          label: '科教卫生',
        },
        {
          value: '291208',
          label: '环境安全',
        },
      ],
    },
    {
      value: '2913',
      label: '游戏',
      children: [
        {
          value: '291301',
          label: '休闲益智',
        },
        {
          value: '291302',
          label: '棋牌桌游',
        },
        {
          value: '291303',
          label: '角色扮演',
        },
        {
          value: '291304',
          label: '动作射击',
        },
        {
          value: '291305',
          label: '竞速体育',
        },
        {
          value: '291306',
          label: '经营养成',
        },
        {
          value: '291307',
          label: '策略塔防',
        },
        {
          value: '291308',
          label: 'MOBA(综合游戏)',
        },
        {
          value: '291309',
          label: '其他游戏',
        },
        {
          value: '291310',
          label: '亲子游戏',
        },
        {
          value: '291311',
          label: '冒险游戏',
        },
        {
          value: '291312',
          label: '赌博元素游戏',
        },
        {
          value: '291313',
          label: '综合类游戏及游戏周边产品服务',
        },
        {
          value: '291314',
          label: '超休闲游戏',
        },
      ],
    },
    {
      value: '2914',
      label: '零售&电商',
      children: [
        {
          value: '291401',
          label: '综合类2B电商',
        },
        {
          value: '291402',
          label: '垂直类2B电商',
        },
        {
          value: '291403',
          label: '综合类2C电商',
        },
        {
          value: '291404',
          label: '社交类电商',
        },
        {
          value: '291405',
          label: '垂直类2C电商',
        },
        {
          value: '291406',
          label: '综合类线下零售',
        },
        {
          value: '291407',
          label: '垂直类线下零售',
        },
        {
          value: '291408',
          label: '其他零售',
        },
      ],
    },
    {
      value: '2915',
      label: '交通工具',
      children: [
        {
          value: '291501',
          label: '飞机厂商',
        },
        {
          value: '291502',
          label: '船舶厂商',
        },
        {
          value: '291504',
          label: '非机动车厂商',
        },
        {
          value: '291505',
          label: '功能性车辆厂商',
        },
        {
          value: '291506',
          label: '其他交通工具',
        },
        {
          value: '291507',
          label: '交通工具后市场',
        },
      ],
    },
    {
      value: '2916',
      label: '机动车',
      children: [
        {
          value: '291601',
          label: '小型汽车厂商',
        },
        {
          value: '291602',
          label: '汽车经销商',
        },
        {
          value: '291603',
          label: '汽车二手交易',
        },
        {
          value: '291604',
          label: '汽车后市场',
        },
        {
          value: '291605',
          label: '其他汽车',
        },
        {
          value: '291606',
          label: '汽车综合服务平台',
        },
        {
          value: '291607',
          label: '新车线下零售',
        },
        {
          value: '291608',
          label: '摩托车厂商',
        },
        {
          value: '291609',
          label: '大中型汽车厂商',
        },
      ],
    },
    {
      value: '2917',
      label: '第一产业',
      children: [
        {
          value: '291701',
          label: '农业',
        },
        {
          value: '291702',
          label: '林业',
        },
        {
          value: '291703',
          label: '渔业',
        },
        {
          value: '291704',
          label: '狩猎业',
        },
        {
          value: '291705',
          label: '畜牧业',
        },
        {
          value: '291706',
          label: '农林服务',
        },
        {
          value: '291707',
          label: '农产品加工',
        },
        {
          value: '291708',
          label: '化肥及农药',
        },
        {
          value: '291709',
          label: '其他第一产业（其他农林牧畜渔）',
        },
      ],
    },
    {
      value: '2918',
      label: '化工及能源',
      children: [
        {
          value: '291801',
          label: '化工制品',
        },
        {
          value: '291802',
          label: '消毒产品',
        },
        {
          value: '291803',
          label: '危险化学品',
        },
        {
          value: '291804',
          label: '食品化工材料',
        },
        {
          value: '291805',
          label: '其他化工',
        },
        {
          value: '291806',
          label: '燃料能源',
        },
        {
          value: '291807',
          label: '电力能源',
        },
        {
          value: '291808',
          label: '新能源',
        },
        {
          value: '291809',
          label: '矿产资源',
        },
        {
          value: '291810',
          label: '污染处理',
        },
        {
          value: '291811',
          label: '废旧回收',
        },
        {
          value: '291812',
          label: '节能',
        },
        {
          value: '291813',
          label: '其他能源',
        },
      ],
    },
    {
      value: '2919',
      label: '电子电工',
      children: [
        {
          value: '291902',
          label: '仪器仪表',
        },
        {
          value: '291903',
          label: '电工电气',
        },
        {
          value: '291904',
          label: '电工机械',
        },
        {
          value: '291905',
          label: '其他电子电工',
        },
      ],
    },
    {
      value: '2920',
      label: '机械设备',
      children: [
        {
          value: '292001',
          label: '通用机械设备',
        },
        {
          value: '292002',
          label: '农林机械',
        },
        {
          value: '292003',
          label: '矿产机械',
        },
        {
          value: '292004',
          label: '建筑工程机械',
        },
        {
          value: '292005',
          label: '化工机械',
        },
        {
          value: '292006',
          label: '木材石材加工机械',
        },
        {
          value: '292007',
          label: '机床机械',
        },
        {
          value: '292008',
          label: '商用设备',
        },
        {
          value: '292009',
          label: '基础机械',
        },
        {
          value: '292010',
          label: '工具配件',
        },
        {
          value: '292011',
          label: '食品机械',
        },
        {
          value: '292013',
          label: '清洁通风设备',
        },
        {
          value: '292014',
          label: '其他机械设备',
        },
      ],
    },
    {
      value: '2921',
      label: '文体娱乐',
      children: [
        {
          value: '292101',
          label: '演出票务及周边',
        },
        {
          value: '292102',
          label: '文化艺术收藏品',
        },
        {
          value: '292103',
          label: '文具玩具礼品',
        },
        {
          value: '292104',
          label: '乐器',
        },
        {
          value: '292105',
          label: '室内娱乐',
        },
        {
          value: '292106',
          label: '运动健身',
        },
        {
          value: '292107',
          label: '体育用品',
        },
        {
          value: '292108',
          label: '户外用品',
        },
        {
          value: '292109',
          label: '体育赛事及场馆',
        },
        {
          value: '292110',
          label: '园艺',
        },
        {
          value: '292112',
          label: '户外娱乐',
        },
        {
          value: '292113',
          label: '其他文体娱乐',
        },
        {
          value: '292114',
          label: '会员卡券',
        },
        {
          value: '292115',
          label: '赌博&博彩&彩票',
        },
        {
          value: '292116',
          label: '文体娱乐电商',
        },
        {
          value: '292117',
          label: '文体娱乐线下零售',
        },
      ],
    },
    {
      value: '2922',
      label: '传媒及内容',
      children: [
        {
          value: '292201',
          label: '电视台',
        },
        {
          value: '292202',
          label: '广播台',
        },
        {
          value: '292203',
          label: '电影公司',
        },
        {
          value: '292204',
          label: '影视音像制作',
        },
        {
          value: '292205',
          label: '在线书籍杂志',
        },
        {
          value: '292207',
          label: '综合资讯',
        },
        {
          value: '292208',
          label: '垂直资讯',
        },
        {
          value: '292209',
          label: '视频直播',
        },
        {
          value: '292210',
          label: '其他传媒及内容',
        },
        {
          value: '292211',
          label: '书籍杂志-实体书',
        },
        {
          value: '292212',
          label: '线上内容传播与发行',
        },
      ],
    },
    {
      value: '2923',
      label: '物流业',
      children: [
        {
          value: '292301',
          label: '快递物流',
        },
        {
          value: '292302',
          label: '货运代理',
        },
        {
          value: '292303',
          label: '特殊运输',
        },
        {
          value: '292304',
          label: '物流基础设施',
        },
        {
          value: '292305',
          label: '其他物流业',
        },
      ],
    },
    {
      value: '2924',
      label: '通信',
      children: [
        {
          value: '292401',
          label: '电信运营商',
        },
        {
          value: '292402',
          label: '虚拟运营商',
        },
        {
          value: '292403',
          label: '通信设备',
        },
        {
          value: '292404',
          label: '其他通信',
        },
      ],
    },
    {
      value: '2925',
      label: '传统金融业',
      children: [
        {
          value: '292501',
          label: '银行业',
        },
        {
          value: '292502',
          label: '证券业',
        },
        {
          value: '292503',
          label: '保险业',
        },
        {
          value: '292504',
          label: '信托业',
        },
        {
          value: '292505',
          label: '担保及保理',
        },
        {
          value: '292506',
          label: '拍卖典当',
        },
        {
          value: '292507',
          label: '期货',
        },
        {
          value: '292508',
          label: '基金',
        },
        {
          value: '292509',
          label: '信用服务机构',
        },
        {
          value: '292510',
          label: '小额贷款公司',
        },
        {
          value: '292511',
          label: '其他传统金融业',
        },
        {
          value: '292512',
          label: '银行卡组织',
        },
      ],
    },
    {
      value: '2926',
      label: '互联网金融',
      children: [
        {
          value: '292601',
          label: '综合理财',
        },
        {
          value: '292602',
          label: '互联网证券平台',
        },
        {
          value: '292604',
          label: '贷款服务',
        },
        {
          value: '292605',
          label: '外汇',
        },
        {
          value: '292606',
          label: '贵金属',
        },
        {
          value: '292607',
          label: 'P2P',
        },
        {
          value: '292608',
          label: '第三方支付',
        },
        {
          value: '292609',
          label: '众筹',
        },
        {
          value: '292610',
          label: '数字货币',
        },
        {
          value: '292611',
          label: '金融门户网站',
        },
        {
          value: '292612',
          label: '互联网银行',
        },
        {
          value: '292613',
          label: '互联网保险',
        },
        {
          value: '292614',
          label: '综合金融平台及工具',
        },
        {
          value: '292615',
          label: '其他互联网金融',
        },
      ],
    },
    {
      value: '2927',
      label: '餐饮服务',
      children: [
        {
          value: '292701',
          label: '餐食',
        },
        {
          value: '292702',
          label: '饮品',
        },
        {
          value: '292703',
          label: '其他餐饮',
        },
      ],
    },
    {
      value: '2928',
      label: '工具类软件',
      children: [
        {
          value: '292801',
          label: '软件工具',
        },
        {
          value: '292802',
          label: '多媒体处理',
        },
        {
          value: '292803',
          label: '社交通讯',
        },
        {
          value: '292804',
          label: '实用工具',
        },
        {
          value: '292805',
          label: '其他工具类软件',
        },
      ],
    },
    {
      value: '2931',
      label: '招商加盟',
      children: [
        {
          value: '293101',
          label: '招商加盟联展平台',
        },
        {
          value: '293102',
          label: '生活用品加盟',
        },
        {
          value: '293103',
          label: '生活服务加盟',
        },
        {
          value: '293104',
          label: '服装配饰加盟',
        },
        {
          value: '293105',
          label: '美容美发加盟',
        },
        {
          value: '293106',
          label: '室内娱乐加盟',
        },
        {
          value: '293107',
          label: '教育培训加盟',
        },
        {
          value: '293108',
          label: '酒店加盟',
        },
        {
          value: '293109',
          label: '餐食加盟',
        },
        {
          value: '293110',
          label: '医疗医药加盟',
        },
        {
          value: '293111',
          label: '房产家居建材加盟',
        },
        {
          value: '293112',
          label: '食品加盟',
        },
        {
          value: '293113',
          label: '工业加盟',
        },
        {
          value: '293114',
          label: '汽车产品加盟',
        },
        {
          value: '293115',
          label: '互联网软件加盟',
        },
        {
          value: '293116',
          label: '招聘中介加盟',
        },
        {
          value: '293117',
          label: '其他招商加盟',
        },
        {
          value: '293118',
          label: '减肥加盟',
        },
        {
          value: '293119',
          label: '饮品烘焙加盟',
        },
        {
          value: '293120',
          label: '保健品加盟',
        },
        {
          value: '293121',
          label: '酒加盟',
        },
        {
          value: '293122',
          label: '农业加盟',
        },
        {
          value: '293123',
          label: '商务服务加盟',
        },
      ],
    },
  ];


  export const CountryCodeList = [
    {
      label: '安道尔',
      value: 'AD',
    },
    {
      label: '阿联酋',
      value: 'AE',
    },
    {
      label: '阿富汗',
      value: 'AF',
    },
    {
      label: '安提瓜和巴布达',
      value: 'AG',
    },
    {
      label: '安圭拉',
      value: 'AI',
    },
    {
      label: '阿尔巴尼亚',
      value: 'AL',
    },
    {
      label: '亚美尼亚',
      value: 'AM',
    },
    {
      label: '安哥拉',
      value: 'AO',
    },
    {
      label: '南极洲',
      value: 'AQ',
    },
    {
      label: '阿根廷',
      value: 'AR',
    },
    {
      label: '美属萨摩亚',
      value: 'AS',
    },
    {
      label: '奥地利',
      value: 'AT',
    },
    {
      label: '澳大利亚',
      value: 'AU',
    },
    {
      label: '阿鲁巴',
      value: 'AW',
    },
    {
      label: '奥兰',
      value: 'AX',
    },
    {
      label: '阿塞拜疆',
      value: 'AZ',
    },
    {
      label: '波黑',
      value: 'BA',
    },
    {
      label: '巴巴多斯',
      value: 'BB',
    },
    {
      label: '孟加拉国',
      value: 'BD',
    },
    {
      label: '比利时',
      value: 'BE',
    },
    {
      label: '布基纳法索',
      value: 'BF',
    },
    {
      label: '保加利亚',
      value: 'BG',
    },
    {
      label: '巴林',
      value: 'BH',
    },
    {
      label: '布隆迪',
      value: 'BI',
    },
    {
      label: '贝宁',
      value: 'BJ',
    },
    {
      label: '圣巴泰勒米',
      value: 'BL',
    },
    {
      label: '百慕大',
      value: 'BM',
    },
    {
      label: '文莱',
      value: 'BN',
    },
    {
      label: '玻利维亚',
      value: 'BO',
    },
    {
      label: '荷兰加勒比区',
      value: 'BQ',
    },
    {
      label: '巴西',
      value: 'BR',
    },
    {
      label: '巴哈马',
      value: 'BS',
    },
    {
      label: '不丹',
      value: 'BT',
    },
    {
      label: '布韦岛',
      value: 'BV',
    },
    {
      label: '博茨瓦纳',
      value: 'BW',
    },
    {
      label: '白俄罗斯',
      value: 'BY',
    },
    {
      label: '伯利兹',
      value: 'BZ',
    },
    {
      label: '加拿大',
      value: 'CA',
    },
    {
      label: '科科斯群岛',
      value: 'CC',
    },
    {
      label: '刚果（金）',
      value: 'CD',
    },
    {
      label: '中非',
      value: 'CF',
    },
    {
      label: '刚果（布）',
      value: 'CG',
    },
    {
      label: '瑞士',
      value: 'CH',
    },
    {
      label: '科特迪瓦',
      value: 'CI',
    },
    {
      label: '库克群岛',
      value: 'CK',
    },
    {
      label: '智利',
      value: 'CL',
    },
    {
      label: '喀麦隆',
      value: 'CM',
    },
    {
      label: '中国',
      value: 'CN',
    },
    {
      label: '哥伦比亚',
      value: 'CO',
    },
    {
      label: '哥斯达黎加',
      value: 'CR',
    },
    {
      label: '古巴',
      value: 'CU',
    },
    {
      label: '佛得角',
      value: 'CV',
    },
    {
      label: '圣诞岛',
      value: 'CX',
    },
    {
      label: '塞浦路斯',
      value: 'CY',
    },
    {
      label: '捷克',
      value: 'CZ',
    },
    {
      label: '德国',
      value: 'DE',
    },
    {
      label: '吉布提',
      value: 'DJ',
    },
    {
      label: '丹麦',
      value: 'DK',
    },
    {
      label: '多米尼克',
      value: 'DM',
    },
    {
      label: '多米尼加',
      value: 'DO',
    },
    {
      label: '阿尔及利亚',
      value: 'DZ',
    },
    {
      label: '厄瓜多尔',
      value: 'EC',
    },
    {
      label: '爱沙尼亚',
      value: 'EE',
    },
    {
      label: '埃及',
      value: 'EG',
    },
    {
      label: '西撒哈拉',
      value: 'EH',
    },
    {
      label: '厄立特里亚',
      value: 'ER',
    },
    {
      label: '西班牙',
      value: 'ES',
    },
    {
      label: '埃塞俄比亚',
      value: 'ET',
    },
    {
      label: '芬兰',
      value: 'FI',
    },
    {
      label: '斐济',
      value: 'FJ',
    },
    {
      label: '福克兰群岛',
      value: 'FK',
    },
    {
      label: '密克罗尼西亚联邦',
      value: 'FM',
    },
    {
      label: '法罗群岛',
      value: 'FO',
    },
    {
      label: '法国',
      value: 'FR',
    },
    {
      label: '加蓬',
      value: 'GA',
    },
    {
      label: '英国',
      value: 'GB',
    },
    {
      label: '格林纳达',
      value: 'GD',
    },
    {
      label: '格鲁吉亚',
      value: 'GE',
    },
    {
      label: '法属圭亚那',
      value: 'GF',
    },
    {
      label: '根西岛',
      value: 'GG',
    },
    {
      label: '加纳',
      value: 'GH',
    },
    {
      label: '直布罗陀',
      value: 'GI',
    },
    {
      label: '格陵兰',
      value: 'GL',
    },
    {
      label: '冈比亚',
      value: 'GM',
    },
    {
      label: '几内亚',
      value: 'GN',
    },
    {
      label: '瓜德罗普',
      value: 'GP',
    },
    {
      label: '赤道几内亚',
      value: 'GQ',
    },
    {
      label: '希腊',
      value: 'GR',
    },
    {
      label: '南乔治亚和南桑威齐群岛',
      value: 'GS',
    },
    {
      label: '危地马拉',
      value: 'GT',
    },
    {
      label: '关岛',
      value: 'GU',
    },
    {
      label: '几内亚比绍',
      value: 'GW',
    },
    {
      label: '圭亚那',
      value: 'GY',
    },
    {
      label: '中国香港地区',
      value: 'HK',
    },
    {
      label: '赫德岛和麦克唐纳群岛',
      value: 'HM',
    },
    {
      label: '洪都拉斯',
      value: 'HN',
    },
    {
      label: '克罗地亚',
      value: 'HR',
    },
    {
      label: '海地',
      value: 'HT',
    },
    {
      label: '匈牙利',
      value: 'HU',
    },
    {
      label: '印度尼西亚',
      value: 'ID',
    },
    {
      label: '爱尔兰',
      value: 'IE',
    },
    {
      label: '以色列',
      value: 'IL',
    },
    {
      label: '马恩岛',
      value: 'IM',
    },
    {
      label: '印度',
      value: 'IN',
    },
    {
      label: '英属印度洋领地',
      value: 'IO',
    },
    {
      label: '伊拉克',
      value: 'IQ',
    },
    {
      label: '伊朗',
      value: 'IR',
    },
    {
      label: '冰岛',
      value: 'IS',
    },
    {
      label: '意大利',
      value: 'IT',
    },
    {
      label: '泽西',
      value: 'JE',
    },
    {
      label: '牙买加',
      value: 'JM',
    },
    {
      label: '约旦',
      value: 'JO',
    },
    {
      label: '日本',
      value: 'JP',
    },
    {
      label: '肯尼亚',
      value: 'KE',
    },
    {
      label: '吉尔吉斯斯坦',
      value: 'KG',
    },
    {
      label: '柬埔寨',
      value: 'KH',
    },
    {
      label: '基里巴斯',
      value: 'KI',
    },
    {
      label: '科摩罗',
      value: 'KM',
    },
    {
      label: '圣基茨和尼维斯',
      value: 'KN',
    },
    {
      label: '朝鲜',
      value: 'KP',
    },
    {
      label: '韩国',
      value: 'KR',
    },
    {
      label: '科威特',
      value: 'KW',
    },
    {
      label: '开曼群岛',
      value: 'KY',
    },
    {
      label: '哈萨克斯坦',
      value: 'KZ',
    },
    {
      label: '老挝',
      value: 'LA',
    },
    {
      label: '黎巴嫩',
      value: 'LB',
    },
    {
      label: '圣卢西亚',
      value: 'LC',
    },
    {
      label: '列支敦士登',
      value: 'LI',
    },
    {
      label: '斯里兰卡',
      value: 'LK',
    },
    {
      label: '利比里亚',
      value: 'LR',
    },
    {
      label: '莱索托',
      value: 'LS',
    },
    {
      label: '立陶宛',
      value: 'LT',
    },
    {
      label: '卢森堡',
      value: 'LU',
    },
    {
      label: '拉脱维亚',
      value: 'LV',
    },
    {
      label: '利比亚',
      value: 'LY',
    },
    {
      label: '摩洛哥',
      value: 'MA',
    },
    {
      label: '摩纳哥',
      value: 'MC',
    },
    {
      label: '摩尔多瓦',
      value: 'MD',
    },
    {
      label: '黑山',
      value: 'ME',
    },
    {
      label: '马达加斯加',
      value: 'MG',
    },
    {
      label: '马绍尔群岛',
      value: 'MH',
    },
    {
      label: '马其顿',
      value: 'MK',
    },
    {
      label: '马里',
      value: 'ML',
    },
    {
      label: '缅甸',
      value: 'MM',
    },
    {
      label: '蒙古国',
      value: 'MN',
    },
    {
      label: '中国澳门',
      value: 'MO',
    },
    {
      label: '北马里亚纳群岛',
      value: 'MP',
    },
    {
      label: '马提尼克',
      value: 'MQ',
    },
    {
      label: '毛里塔尼亚',
      value: 'MR',
    },
    {
      label: '蒙特塞拉特',
      value: 'MS',
    },
    {
      label: '马耳他',
      value: 'MT',
    },
    {
      label: '毛里求斯',
      value: 'MU',
    },
    {
      label: '马尔代夫',
      value: 'MV',
    },
    {
      label: '马拉维',
      value: 'MW',
    },
    {
      label: '墨西哥',
      value: 'MX',
    },
    {
      label: '马来西亚',
      value: 'MY',
    },
    {
      label: '莫桑比克',
      value: 'MZ',
    },
    {
      label: '纳米比亚',
      value: 'NA',
    },
    {
      label: '新喀里多尼亚',
      value: 'NC',
    },
    {
      label: '尼日尔',
      value: 'NE',
    },
    {
      label: '诺福克岛',
      value: 'NF',
    },
    {
      label: '尼日利亚',
      value: 'NG',
    },
    {
      label: '尼加拉瓜',
      value: 'NI',
    },
    {
      label: '荷兰',
      value: 'NL',
    },
    {
      label: '挪威',
      value: 'NO',
    },
    {
      label: '尼泊尔',
      value: 'NP',
    },
    {
      label: '瑙鲁',
      value: 'NR',
    },
    {
      label: '纽埃',
      value: 'NU',
    },
    {
      label: '新西兰',
      value: 'NZ',
    },
    {
      label: '阿曼',
      value: 'OM',
    },
    {
      label: '巴拿马',
      value: 'PA',
    },
    {
      label: '秘鲁',
      value: 'PE',
    },
    {
      label: '法属波利尼西亚',
      value: 'PF',
    },
    {
      label: '巴布亚新几内亚',
      value: 'PG',
    },
    {
      label: '菲律宾',
      value: 'PH',
    },
    {
      label: '巴基斯坦',
      value: 'PK',
    },
    {
      label: '波兰',
      value: 'PL',
    },
    {
      label: '圣皮埃尔和密克隆',
      value: 'PM',
    },
    {
      label: '皮特凯恩群岛',
      value: 'PN',
    },
    {
      label: '波多黎各',
      value: 'PR',
    },
    {
      label: '巴勒斯坦*',
      value: 'PS',
    },
    {
      label: '葡萄牙',
      value: 'PT',
    },
    {
      label: '帕劳',
      value: 'PW',
    },
    {
      label: '巴拉圭',
      value: 'PY',
    },
    {
      label: '卡塔尔',
      value: 'QA',
    },
    {
      label: '留尼汪',
      value: 'RE',
    },
    {
      label: '罗马尼亚',
      value: 'RO',
    },
    {
      label: '塞尔维亚',
      value: 'RS',
    },
    {
      label: '俄罗斯',
      value: 'RU',
    },
    {
      label: '卢旺达',
      value: 'RW',
    },
    {
      label: '沙特阿拉伯',
      value: 'SA',
    },
    {
      label: '所罗门群岛',
      value: 'SB',
    },
    {
      label: '塞舌尔',
      value: 'SC',
    },
    {
      label: '苏丹',
      value: 'SD',
    },
    {
      label: '瑞典',
      value: 'SE',
    },
    {
      label: '新加坡',
      value: 'SG',
    },
    {
      label: '圣赫勒拿',
      value: 'SH',
    },
    {
      label: '斯洛文尼亚',
      value: 'SI',
    },
    {
      label: '斯瓦巴和扬马延',
      value: 'SJ',
    },
    {
      label: '斯洛伐克',
      value: 'SK',
    },
    {
      label: '塞拉利昂',
      value: 'SL',
    },
    {
      label: '圣马力诺',
      value: 'SM',
    },
    {
      label: '塞内加尔',
      value: 'SN',
    },
    {
      label: '索马里',
      value: 'SO',
    },
    {
      label: '苏里南',
      value: 'SR',
    },
    {
      label: '南苏丹',
      value: 'SS',
    },
    {
      label: '圣多美和普林西比',
      value: 'ST',
    },
    {
      label: '萨尔瓦多',
      value: 'SV',
    },
    {
      label: '叙利亚',
      value: 'SY',
    },
    {
      label: '斯威士兰',
      value: 'SZ',
    },
    {
      label: '特克斯和凯科斯群岛',
      value: 'TC',
    },
    {
      label: '乍得',
      value: 'TD',
    },
    {
      label: '法属南部领地',
      value: 'TF',
    },
    {
      label: '多哥',
      value: 'TG',
    },
    {
      label: '泰国',
      value: 'TH',
    },
    {
      label: '塔吉克斯坦',
      value: 'TJ',
    },
    {
      label: '托克劳',
      value: 'TK',
    },
    {
      label: '东帝汶',
      value: 'TL',
    },
    {
      label: '土库曼斯坦',
      value: 'TM',
    },
    {
      label: '突尼斯',
      value: 'TN',
    },
    {
      label: '汤加',
      value: 'TO',
    },
    {
      label: '土耳其',
      value: 'TR',
    },
    {
      label: '特立尼达和多巴哥',
      value: 'TT',
    },
    {
      label: '图瓦卢',
      value: 'TV',
    },
    {
      label: '中国台湾地区',
      value: 'TW',
    },
    {
      label: '坦桑尼亚',
      value: 'TZ',
    },
    {
      label: '乌克兰',
      value: 'UA',
    },
    {
      label: '乌干达',
      value: 'UG',
    },
    {
      label: '美国本土外小岛屿',
      value: 'UM',
    },
    {
      label: '美国',
      value: 'US',
    },
    {
      label: '乌拉圭',
      value: 'UY',
    },
    {
      label: '乌兹别克斯坦',
      value: 'UZ',
    },
    {
      label: '梵蒂冈',
      value: 'VA',
    },
    {
      label: '圣文森特和格林纳丁斯',
      value: 'VC',
    },
    {
      label: '委内瑞拉',
      value: 'VE',
    },
    {
      label: '英属维尔京群岛',
      value: 'VG',
    },
    {
      label: '美属维尔京群岛',
      value: 'VI',
    },
    {
      label: '越南',
      value: 'VN',
    },
    {
      label: '瓦努阿图',
      value: 'VU',
    },
    {
      label: '瓦利斯和富图纳',
      value: 'WF',
    },
    {
      label: '萨摩亚',
      value: 'WS',
    },
    {
      label: '也门',
      value: 'YE',
    },
    {
      label: '马约特',
      value: 'YT',
    },
    {
      label: '南非',
      value: 'ZA',
    },
    {
      label: '赞比亚',
      value: 'ZM',
    },
    {
      label: '津巴布韦',
      value: 'ZW',
    },
  
  ];

  export const CountryCodeEnumEn = {
    "AD": "安道尔",
    "AE": "阿联酋",
    "AF": "阿富汗",
    "AG": "安提瓜和巴布达",
    "AI": "安圭拉",
    "AL": "阿尔巴尼亚",
    "AM": "亚美尼亚",
    "AO": "安哥拉",
    "AQ": "南极洲",
    "AR": "阿根廷",
    "AS": "美属萨摩亚",
    "AT": "奥地利",
    "AU": "澳大利亚",
    "AW": "阿鲁巴",
    "AX": "奥兰",
    "AZ": "阿塞拜疆",
    "BA": "波黑",
    "BB": "巴巴多斯",
    "BD": "孟加拉国",
    "BE": "比利时",
    "BF": "布基纳法索",
    "BG": "保加利亚",
    "BH": "巴林",
    "BI": "布隆迪",
    "BJ": "贝宁",
    "BL": "圣巴泰勒米",
    "BM": "百慕大",
    "BN": "文莱",
    "BO": "玻利维亚",
    "BQ": "荷兰加勒比区",
    "BR": "巴西",
    "BS": "巴哈马",
    "BT": "不丹",
    "BV": "布韦岛",
    "BW": "博茨瓦纳",
    "BY": "白俄罗斯",
    "BZ": "伯利兹",
    "CA": "加拿大",
    "CC": "科科斯群岛",
    "CD": "刚果（金）",
    "CF": "中非",
    "CG": "刚果（布）",
    "CH": "瑞士",
    "CI": "科特迪瓦",
    "CK": "库克群岛",
    "CL": "智利",
    "CM": "喀麦隆",
    "CN": "中国",
    "CO": "哥伦比亚",
    "CR": "哥斯达黎加",
    "CU": "古巴",
    "CV": "佛得角",
    "CX": "圣诞岛",
    "CY": "塞浦路斯",
    "CZ": "捷克",
    "DE": "德国",
    "DJ": "吉布提",
    "DK": "丹麦",
    "DM": "多米尼克",
    "DO": "多米尼加",
    "DZ": "阿尔及利亚",
    "EC": "厄瓜多尔",
    "EE": "爱沙尼亚",
    "EG": "埃及",
    "EH": "西撒哈拉",
    "ER": "厄立特里亚",
    "ES": "西班牙",
    "ET": "埃塞俄比亚",
    "FI": "芬兰",
    "FJ": "斐济",
    "FK": "福克兰群岛",
    "FM": "密克罗尼西亚联邦",
    "FO": "法罗群岛",
    "FR": "法国",
    "GA": "加蓬",
    "GB": "英国",
    "GD": "格林纳达",
    "GE": "格鲁吉亚",
    "GF": "法属圭亚那",
    "GG": "根西岛",
    "GH": "加纳",
    "GI": "直布罗陀",
    "GL": "格陵兰",
    "GM": "冈比亚",
    "GN": "几内亚",
    "GP": "瓜德罗普",
    "GQ": "赤道几内亚",
    "GR": "希腊",
    "GS": "南乔治亚和南桑威齐群岛",
    "GT": "危地马拉",
    "GU": "关岛",
    "GW": "几内亚比绍",
    "GY": "圭亚那",
    "HK": "中国香港地区",
    "HM": "赫德岛和麦克唐纳群岛",
    "HN": "洪都拉斯",
    "HR": "克罗地亚",
    "HT": "海地",
    "HU": "匈牙利",
    "ID": "印度尼西亚",
    "IE": "爱尔兰",
    "IL": "以色列",
    "IM": "马恩岛",
    "IN": "印度",
    "IO": "英属印度洋领地",
    "IQ": "伊拉克",
    "IR": "伊朗",
    "IS": "冰岛",
    "IT": "意大利",
    "JE": "泽西",
    "JM": "牙买加",
    "JO": "约旦",
    "JP": "日本",
    "KE": "肯尼亚",
    "KG": "吉尔吉斯斯坦",
    "KH": "柬埔寨",
    "KI": "基里巴斯",
    "KM": "科摩罗",
    "KN": "圣基茨和尼维斯",
    "KP": "朝鲜",
    "KR": "韩国",
    "KW": "科威特",
    "KY": "开曼群岛",
    "KZ": "哈萨克斯坦",
    "LA": "老挝",
    "LB": "黎巴嫩",
    "LC": "圣卢西亚",
    "LI": "列支敦士登",
    "LK": "斯里兰卡",
    "LR": "利比里亚",
    "LS": "莱索托",
    "LT": "立陶宛",
    "LU": "卢森堡",
    "LV": "拉脱维亚",
    "LY": "利比亚",
    "MA": "摩洛哥",
    "MC": "摩纳哥",
    "MD": "摩尔多瓦",
    "ME": "黑山",
    "MG": "马达加斯加",
    "MH": "马绍尔群岛",
    "MK": "马其顿",
    "ML": "马里",
    "MM": "缅甸",
    "MN": "蒙古国",
    "MO": "中国澳门",
    "MP": "北马里亚纳群岛",
    "MQ": "马提尼克",
    "MR": "毛里塔尼亚",
    "MS": "蒙特塞拉特",
    "MT": "马耳他",
    "MU": "毛里求斯",
    "MV": "马尔代夫",
    "MW": "马拉维",
    "MX": "墨西哥",
    "MY": "马来西亚",
    "MZ": "莫桑比克",
    "NA": "纳米比亚",
    "NC": "新喀里多尼亚",
    "NE": "尼日尔",
    "NF": "诺福克岛",
    "NG": "尼日利亚",
    "NI": "尼加拉瓜",
    "NL": "荷兰",
    "NO": "挪威",
    "NP": "尼泊尔",
    "NR": "瑙鲁",
    "NU": "纽埃",
    "NZ": "新西兰",
    "OM": "阿曼",
    "PA": "巴拿马",
    "PE": "秘鲁",
    "PF": "法属波利尼西亚",
    "PG": "巴布亚新几内亚",
    "PH": "菲律宾",
    "PK": "巴基斯坦",
    "PL": "波兰",
    "PM": "圣皮埃尔和密克隆",
    "PN": "皮特凯恩群岛",
    "PR": "波多黎各",
    "PS": "巴勒斯坦*",
    "PT": "葡萄牙",
    "PW": "帕劳",
    "PY": "巴拉圭",
    "QA": "卡塔尔",
    "RE": "留尼汪",
    "RO": "罗马尼亚",
    "RS": "塞尔维亚",
    "RU": "俄罗斯",
    "RW": "卢旺达",
    "SA": "沙特阿拉伯",
    "SB": "所罗门群岛",
    "SC": "塞舌尔",
    "SD": "苏丹",
    "SE": "瑞典",
    "SG": "新加坡",
    "SH": "圣赫勒拿",
    "SI": "斯洛文尼亚",
    "SJ": "斯瓦巴和扬马延",
    "SK": "斯洛伐克",
    "SL": "塞拉利昂",
    "SM": "圣马力诺",
    "SN": "塞内加尔",
    "SO": "索马里",
    "SR": "苏里南",
    "SS": "南苏丹",
    "ST": "圣多美和普林西比",
    "SV": "萨尔瓦多",
    "SY": "叙利亚",
    "SZ": "斯威士兰",
    "TC": "特克斯和凯科斯群岛",
    "TD": "乍得",
    "TF": "法属南部领地",
    "TG": "多哥",
    "TH": "泰国",
    "TJ": "塔吉克斯坦",
    "TK": "托克劳",
    "TL": "东帝汶",
    "TM": "土库曼斯坦",
    "TN": "突尼斯",
    "TO": "汤加",
    "TR": "土耳其",
    "TT": "特立尼达和多巴哥",
    "TV": "图瓦卢",
    "TW": "中国台湾地区",
    "TZ": "坦桑尼亚",
    "UA": "乌克兰",
    "UG": "乌干达",
    "UM": "美国本土外小岛屿",
    "US": "美国",
    "UY": "乌拉圭",
    "UZ": "乌兹别克斯坦",
    "VA": "梵蒂冈",
    "VC": "圣文森特和格林纳丁斯",
    "VE": "委内瑞拉",
    "VG": "英属维尔京群岛",
    "VI": "美属维尔京群岛",
    "VN": "越南",
    "VU": "瓦努阿图",
    "WF": "瓦利斯和富图纳",
    "WS": "萨摩亚",
    "YE": "也门",
    "YT": "马约特",
    "ZA": "南非",
    "ZM": "赞比亚",
    "ZW": "津巴布韦"
}

export const AdvStatus = {
  SHOW_ACCOUNT_STATUS_NOT_APPROVED: '未通过',
  SHOW_ACCOUNT_STATUS_APPROVED: '已通过',
  SHOW_ACCOUNT_STATUS_IN_REVIEW: '审核中',
  SHOW_ACCOUNT_STATUS_PUNISHED:'惩罚中'
}
  
export const BCList = [
  {
    BCName:"新速率&Hanglixun-1",
    BCID:"6973924533950480386"
  },
  {
    BCName: "新速率&Hanglixun-2",
    BCID:"7161356844022169601"
  },
  {
    BCName: "新速率&NewRate-1",
    BCID:"7155780945373593601"
  },
  {
    BCName: "新速率&NewRate-2",
    BCID:"7229250006648193026"
  },
  {
    BCName: "新速率&NewRate-3",
    BCID:"7280439827038994434"
  },
  {
    BCName: "新速率&NewRate-4",
    BCID:"7287110413530038273"
  },
]


