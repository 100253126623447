// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"name":"用户列表","icon":"UserOutlined","path":"/admin/userList","access":"superAdmin","parentId":"ant-design-pro-layout","id":"1"},"2":{"name":"充值管理","icon":"DollarOutlined","path":"/admin/recharge","access":"superAdmin","parentId":"ant-design-pro-layout","id":"2"},"3":{"name":"批量开户","icon":"FormOutlined","path":"/admin/openAccount","access":"Analyze","parentId":"ant-design-pro-layout","id":"3"},"4":{"name":"批量开户表单","icon":"FormOutlined","path":"/admin/openAccount/form","access":"Analyze","hideInMenu":true,"parentId":"ant-design-pro-layout","id":"4"},"5":{"name":"钱包充值","icon":"accountBook","path":"/payment","parentId":"ant-design-pro-layout","id":"5"},"6":{"name":"广告账户","title":"menu.advertise.account.title","icon":"GlobalOutlined","path":"/account","parentId":"ant-design-pro-layout","id":"6"},"7":{"name":"修改密码","title":"menu.advertise.account.title","icon":"GlobalOutlined","path":"/password","hideInMenu":true,"parentId":"ant-design-pro-layout","id":"7"},"8":{"name":"实名认证","title":"menu.advertise.account.title","icon":"GlobalOutlined","path":"/realname","hideInMenu":true,"parentId":"ant-design-pro-layout","id":"8"},"9":{"name":"登录","path":"/login","exact":true,"layout":false,"id":"9"},"10":{"name":"注册","path":"/register","exact":true,"layout":false,"id":"10"},"11":{"name":"找回密码","path":"/findPassword","exact":true,"layout":false,"id":"11"},"12":{"path":"/","exact":true,"redirect":"/login","parentId":"ant-design-pro-layout","id":"12"},"13":{"path":"/*","parentId":"ant-design-pro-layout","id":"13"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Admin__index" */'@/pages/Admin/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__RechargeManage__index" */'@/pages/RechargeManage/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__BatchOpenAccount__index" */'@/pages/BatchOpenAccount/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__BatchOpenAccount__OpenAccount" */'@/pages/BatchOpenAccount/OpenAccount.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__Payment__index" */'@/pages/Payment/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__AdAccount__index" */'@/pages/AdAccount/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__User__EditPassword" */'@/pages/User/EditPassword.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__User__Realname" */'@/pages/User/Realname.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__User__Login" */'@/pages/User/Login.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__User__Register" */'@/pages/User/Register.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__User__FindPassword" */'@/pages/User/FindPassword.tsx')),
'12': React.lazy(() => import( './EmptyRoute')),
'13': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/Users/zhengwei/xinsulv/TT_Business_Admin_3.0/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
